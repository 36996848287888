<template>
  <header class="gc-header">
    <a class="link" href="/">
      <img
        src="../assets/images/logo-flat.svg"
        alt=""
        class="gc-header__logo"
      />
    </a>
    <nav class="gc-header__nav">
      <li class="gc-header__nav-item">
        <a
          class="link gc-footer__social-icon-link gc-footer__social-icon-link--whatsapp"
          href="https://wa.me/2348163580180"
          target="_blank"
        >
          <img src="../assets/icons/whatsapp-icon.svg" alt="" />
        </a>
      </li>
      <li class="gc-header__nav-item">
        <a href="https://forms.gle/tA3SDEuY3XYWu6p18" class="link btn-link gc-donate-btn"
          >Donate</a
        >
      </li>
    </nav>
  </header>

  <section class="gc-hero">
    <div class="gc-hero-content">
      <h1 class="gc-hero-content__top">
        Your old books can give a
        <span class="gc-hero-content__top--huge">smile</span> you know!
      </h1>
      <p class="gc-hero-content__sub">
        Donate your old books to people that cannot afford it.
      </p>
      <a
        href="https://forms.gle/tA3SDEuY3XYWu6p18"
        class="link btn-link gc-donate-btn gc-hero-content__cta-btn"
        >Donate<span>, thank you!</span></a
      >
    </div>
  </section>

  <section class="gc-model">
    <h2 class="gc-model__heading">How can I join?</h2>
    <div class="row gc-model__list">
      <div class="col-1-of-3 u-align-text">
        <figure class="event">
          <img
            class="event__image"
            src="../assets/images/propose-illustration.svg"
            alt=""
            srcset=""
          />
          <figcaption class="event__caption">
            <div class="event__caption-title">Propose</div>
            <div class="event__caption-details">
              Make a decision to donate a book. Click the
              <a class="link" href="https://forms.gle/tA3SDEuY3XYWu6p18">donate</a> button for this.
            </div>
          </figcaption>
          <div class="event__step">
            <span>Step</span>
            <span>1</span>
          </div>
        </figure>
      </div>

      <div class="col-1-of-3 u-align-text">
        <figure class="event">
          <img
            class="event__image"
            src="../assets/images/pick-hub-illustration.svg"
            alt=""
            srcset=""
          />
          <figcaption class="event__caption">
            <div class="event__caption-title">Pick Hub</div>
            <div class="event__caption-details">
              Select the location closest to you to drop the book promised.
            </div>
          </figcaption>
          <div class="event__step">
            <span>Step</span>
            <span>2</span>
          </div>
        </figure>
      </div>

      <div class="col-1-of-3 u-align-text">
        <figure class="event">
          <img
            class="event__image"
            src="../assets/images/fulfil-promise-illustration.svg"
            alt=""
            srcset=""
          />
          <figcaption class="event__caption">
            <div class="event__caption-title">Fulfil</div>
            <div class="event__caption-details">
              Fulfil commitment: submit the book at the selected location.
            </div>
          </figcaption>
          <div class="event__step">
            <span>Step</span>
            <span>3</span>
          </div>
        </figure>
      </div>
    </div>
  </section>

  <footer class="gc-footer">
    <div class="gc-footer__content">
      <div class="row">
        <div class="col-1-of-3">
          <a class="link" href="/">
            <img
              src="../assets/images/logo-flat.svg"
              alt=""
              class="gc-footer__logo"
            />
          </a>
          <p class="gc-footer__note">
            GiveChief is dedicated to providing access to book to students from
            beautiful donors.
          </p>
        </div>

        <div class="col-1-of-3 u-align-text">
          <a
            class="link gc-footer__social-icon-link"
            href="https://www.instagram.com/givechief"
            target="_blank"
          >
            <img src="../assets/icons/instagram-icon.svg" alt="" />
          </a>

          <a
            class="link gc-footer__social-icon-link"
            href="https://medium.com/@givechief"
            target="_blank"
          >
            <img src="../assets/icons/medium-icon.svg" alt="" />
          </a>
        </div>

        <div class="col-1-of-3">
          <ul class="gc-footer__actions">
            <!-- <li><a class="link" href="">Policy</a></li>
            <li><a class="link" href="">Terms Of Use</a></li> -->
            <li>
              <a
                class="link"
                href="https://www.instagram.com/givechief"
                target="_blank"
                >Created with ❤️ in 🇳🇬</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <p class="gc-footer__copyright">
      GiveChief © {{ new Date().getFullYear() }}
    </p>
  </footer>
</template>

<style lang="scss" scoped>
@import "../assets/styles/mixins";

.gc-header,
.gc-hero,
.gc-footer__content {
  max-width: 120rem;
  margin: auto;
  padding: 1.8rem 4rem;
  position: relative;

  @include respond(phone) {
    padding: 1rem 2rem;
  }
}
.gc-donate-btn {
  background: var(--color-primary);
  border-radius: 10px;
  font-size: 1.8rem;
  @include respond(smaller-phone) {
    font-size: 2rem;
  }
}
.gc-header {
  position: relative;

  display: flex;
    justify-content: space-between;
    align-items: center;

  a {
    display: inline-block;
  }

  &__logo {
    height: 5rem;

    @include respond(phone) {
      height: 4rem;
    }
  }

  &__nav {
    list-style: none;
    display: flex;
    align-items: center;

    @include respond(phone) {
      right: 2rem;
    }
  }

  &__nav-item {
    display: inline-block;
    margin: auto;
    margin-left: 1rem;
  }

  &__nav-link {
    color: var(--color-secondary);
    font-weight: 600;
    font-size: 2rem;

    @include respond(phone) {
      font-size: 1.8rem;
    }
    @include respond(smaller-phone) {
      font-size: 1.6rem;
    }
  }

  @include respond(phone) {
    position: fixed;
    width: 100%;
    z-index: 10;
    background-color: white;
    max-width: initial;
    padding: 2rem;
  }
}
.gc-hero {
  width: 100%;
  min-height: 70vh;
  background-image: url(../assets/images/hero-illustration-image.svg);
  background-repeat: no-repeat;
  background-position: 90%;
  background-size: contain;
  z-index: 1;

  @include respond(phone) {
    padding: 0;
    top: 7rem;
  }
}
.gc-hero-content {
  height: fit-content;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  &__top {
    font-size: 5rem;
    font-weight: 400;
    max-width: 18ch;
    line-height: 1.2em;
    margin-bottom: 1rem;
    color: var(--color-secondary);

    &--huge {
      font-weight: 700;
    }

    @include respond(phone) {
      max-width: 12ch;
      font-weight: 600;
    }
  }

  &__sub {
    font-size: 2rem;
    color: var(--color-sharp-black);
    max-width: 34ch;
    font-weight: 600;

    @include respond(tab-land) {
      font-size: 2rem;
      width: 28ch;
    }
  }

  &__cta-btn {
    margin-top: 4rem;
    font-size: 2rem;
    animation: move-in-from-bottom 0.3s ease-in 0.3s;
    animation-fill-mode: backwards;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: hidden;
    padding: 1rem 3rem;
    transition: width 0.4s 0.1s;
    width: 12.8rem;

    & span {
      position: absolute;
      display: inline-block;
      transform: translateX(30rem);
      opacity: 0;
      transition: opacity 0.5s, transform 0.4s 0.5s;
      font-weight: 400;
    }

    &:hover {
      width: 24rem;

      & span {
        position: initial;
        transform: translateX(0);
        opacity: 1;
      }
    }

    @include respond(phone) {
      display: none;
    }
  }

  @include respond(phone) {
    height: 100%;
    background-color: rgba(white, 0.5);
    backdrop-filter: blur(10px);
    padding: 6rem 3rem;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.gc-model {
  position: relative;
  background-color: var(--color-secondary);
  margin-top: -5rem;
  padding: 7rem;
  text-align: center;

  &__heading {
    display: inline-block;
    width: fit-content;
    font-size: 4rem;
    font-weight: 600;
    color: white;
  }

  &__list {
    margin-top: 7rem;
  }

  @include respond(phone) {
    margin-top: 5rem;
    padding: 4rem 0;
  }
}
.gc-footer {
  background-color: var(--color-low-black);
  font-size: 1.5rem;
  color: var(--color-grey);
  padding: 2rem 0;

  &__content {
    padding: 3rem;
    border-bottom: 0.1px dashed var(--color-grey);

    @include respond(phone) {
      text-align: center;
    }
  }

  &__logo {
    width: 15rem;
  }

  &__note {
    max-width: 27ch;
    margin: 1rem 0;

    @include respond(phone) {
      margin: auto;
    }
  }

  &__social-icon-link {
    margin: 1rem;
    display: inline-block;

    & img {
      height: 4rem;
    }

    &--whatsapp {
      & img {
        height: 6rem;
      }

      @include respond(phone) {
        position: fixed;
        bottom: 5rem;
        right: 1rem;

        & img {
          height: 7.5rem;
        }
      }
    }
  }

  &__actions {
    list-style: none;
    text-align: right;

    & li {
      margin: 1rem 0;

      & a {
        color: var(--color-grey);
      }

      @include respond(phone) {
        margin: 1rem;
        display: inline-block;
        white-space: nowrap;
      }
    }

    @include respond(phone) {
      text-align: center;
    }
  }

  &__copyright {
    margin: 3rem auto;
    width: fit-content;
  }

  @include respond(phone) {
    font-size: 2rem;
  }
}
</style>
